import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  TextField,
  LinearProgress,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Collapse,
  Alert,
  Snackbar,
  Tooltip,
  SnackbarContent,
  Divider,
  Pagination,
} from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import {
  CloudUpload,
  Delete,
  Close,
  Settings,
  ExitToApp,
} from "@mui/icons-material";
import {
  borderRadius,
  fontFamily,
  fontWeight,
  margin,
  padding,
  Stack,
  styled,
  width,
} from "@mui/system";

import { PdfIcon, ScanQrIcon, UploadIcon } from "../../assets/svg";
import theme from "../../components/theme";

import {
  StyledWhiteButton,
  StyledField,
  StyledFormLabel,
  StyledTextField,
} from "../../components/Cores/FormCores/style";
import PrivateLayout from "../../Layout/PrivateLayout";
import { useNavigate } from "react-router-dom";
import {
  addFileEmbedding,
  deleteFiles,
  generateSnippet,
  updateFileEmbedding,
} from "../../services/auth.services";
import { useDropzone } from "react-dropzone";
import SearchIcon from "@mui/icons-material/Search";
import ConfirmationPopup from "../../Layout/PrivateLayout/ConfirmationPopup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { apiUrls, DOMAIN, key } from "../../Utilities/properties";
import axiosInstance from "../../Utilities/axiosInstance";
import { Link } from "../../components/Cores/Link";
import chatCopyIcon from "../../assets/svg/Chat-copy-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios, { CancelTokenSource } from "axios";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import { useAvatarContext } from "../../context/AvatarContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import {
  AITypograpyColor,
  AppBarStyle,
  CloseCursor,
  CompanyNameDialogContent,
  ConnectionOl,
  CopyButtonStyle,
  CopyButtonStyleScript,
  DashboardTypograpyContainer,
  DialogActionStyle,
  DialogContentBox,
  DialogContentBoxStyle,
  DialogContentStyle,
  DialogTitleStyle,
  DocumentGridMainContainer,
  DocumentGridSubContainer,
  DocumentManagementTypograpyContainer,
  DocumentsTypograpy,
  DocumentSupportTypograpy,
  DownloadButton,
  DownloadconStyle,
  DropDownUploadBox,
  InfoFileTypography,
  InfoIconStyle,
  LinearProgressContainer,
  ListItemContainer,
  ListItemPdf,
  ListItemPdfBox,
  ListItemTextContainer,
  PaginationStyle,
  PdfProcessingTypography,
  QrCodeTypography,
  QRContainer,
  QRDialogContainer,
  RequiredTypograpyColor,
  RightTypograpy,
  ScanQRCodeMobileTypography,
  ScanQrCodeOl,
  ScanQRCodeTypography,
  ScanQrCodeTypography,
  SnacKBarStyle,
  StapsConnectTypography,
  StepstoConnectTypography,
  SubdomainTypography,
  TemperListItemTextContainer,
  TooltipIconStyle,
  UploadBox,
  UploadFileTypograpy,
  UploadingpdfProgressBoxContainer,
  UploadingpdfProgressTypograpyContainer,
} from "./style";
import {
  GridContainer,
  GridItem,
} from "../../components/StyledComponents/Index";
import { SearchBar } from "../../components/Cores/SearchBar";

//pagination
const ITEMS_PER_PAGE = 3;

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <UploadingpdfProgressBoxContainer>
      <UploadingpdfProgressTypograpyContainer>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontSize: "12px" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </UploadingpdfProgressTypograpyContainer>
      <LinearProgressContainer>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </LinearProgressContainer>
    </UploadingpdfProgressBoxContainer>
  );
}

const Home: React.FC = () => {
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<
    { id: number; name: string; description: string }[]
  >([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [qropen, setQrOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filename, setFilename] = useState("");
  const [description, setDescription] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [updatingEmbed, setUpdatingEmbed] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoFile, setInfoFile] = useState<{
    name: string;
    description: string;
  } | null>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState<number | null>(null);
  const [clicked, setClicked] = useState(false);
  const [openAlertFetch, setOpenAlertFetch] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileTotalSize, setFileTotalSize] = useState<number | null>(null);
  const [companyLink, setCompanyLink] = useState<string>("");
  const company_code = localStorage.getItem("company_code");
  const qrRef = useRef<HTMLDivElement>(null);
  const { userCompanyName } = useAvatarContext();
  const sign = require("jwt-encode");
  const [companyJwt, setCompanyJwt] = useState<string>("");
  const [codeSnippet, setCodeSnippet] = useState<string>("");

  const handleQrDownload = async () => {
    if (qrRef.current) {
      const pngUrl = await toPng(qrRef.current);
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = "qr-code.png";
      link.click();
    }
  };

  //fetch userdetails
  useEffect(() => {
    setCompanyJwt(sign({ code: company_code }, key.SECRET_KEY));
    fetchUrl();
    fetchFilesFromServer();
  }, []);

  const fetchFilesFromServer = async () => {
    try {
      const response = await axiosInstance.get(`${apiUrls.USER}/files`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const files = response.data.files;
      setUploadedFiles(files);
      console.log(files);

      // Calculate the total size of all files
      const totalSize = files.reduce(
        (sum: number, file: { size: number }) => sum + file.size,
        0
      );
      console.log("Total size of all files (in bytes):", totalSize);
      setFileTotalSize(totalSize);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        console.log("error:", errMsg);
      }
    }
  };

  const fetchUrl = async () => {
    try {
      const response = await generateSnippet();
      const url = response.data.url;
      console.log(response);
      setCodeSnippet(url);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        console.log("error:", errMsg);
      }
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      console.log(file);
      console.log(file.type);

      // Validate file type
      if (file.type !== "application/pdf") {
        setErrorMessage("Invalid format: Only PDF files are allowed.");
        return;
      }

      // Validate file size (5MB minimum)
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("File size too large: Maximum allowed size is 5MB.");
        return;
      }

      if (fileTotalSize) {
        if (file.size + fileTotalSize > 50 * 1024 * 1024) {
          const availableSpace =
            Math.floor(
              ((50 * 1024 * 1024 - fileTotalSize) / 1024 / 1024) * 10
            ) / 10;
          setErrorMessage(
            "The total file size exceeds the 50 MB limit. Available space remaining: " +
              availableSpace +
              " MB."
          );
          return;
        }
      }

      setErrorMessage(null);
      setSelectedFile(file);
      setOpen(true);
    } else {
      setErrorMessage("Invalid file format");
    }
  };

 
  useEffect(() => {
    if (selectedFile) {
      setFilename(selectedFile.name.split(".")[0]);
    }
  }, [selectedFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const handleClose = () => {
    setOpen(false);
    setDescription("");
  };

  const handleQrClose = () => {
    setQrOpen(false);
  };

  //cancel the upload progress
  const handleCancel = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload canceled by the user.");
      console.log("Cancel token executed");
      setSelectedFile(null);
      setUploading(false);
      setProgress(0);
    } else {
      console.log("No cancel token available");
    }
  };

  //service of upload process
  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("pdf_file", selectedFile);
    formData.append("file_name", filename);
    formData.append("description", description);

    cancelTokenSource.current = axios.CancelToken.source();

    try {
      handleClose();
      setUploading(true);
      setProgress(0);
      const response = await axiosInstance.post(
        `${apiUrls.USER}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: cancelTokenSource.current.token,
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total || 0;
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / total
            );
            setProgress(percentCompleted);
          },
        }
      );
      fetchFilesFromServer();
      const fileId = response.data.file_id;
      setMessage(response.data.message);
      setUploading(false);
      setSelectedFile(null);
      try {
        setUpdatingEmbed(true);
        setOpenAlertFetch(true);
        const addresponse = await addFileEmbedding(fileId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(addresponse.data);
        try {
          const updateresponse = await updateFileEmbedding({
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(updateresponse.data);
          setUpdatingEmbed(false);
          setTimeout(() => {
            setOpenAlertFetch(false);
          }, 6000);
        } catch (error: any) {
          if (error.updateresponse && error.updateresponse.error) {
            const errMsg = error.updateresponse.error;
            console.log("Error updating file embedding:", errMsg);
          }
          setUpdatingEmbed(false);
          setMessage("Error updating file embedding");
        }
      } catch (error: any) {
        if (error.addresponse && error.addresponse.error) {
          const errMsg = error.addresponse.error;
          console.log("Error add newfile embedding:", errMsg);
        }
        setUpdatingEmbed(false);
        setMessage("Error add newfile embedding");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Upload canceled:", error.message);
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);
      setSelectedFile(null);
      cancelTokenSource.current = null;
    }
  };

  // service of file delete
  const handleDeleteConfirmation = (fileId: number) => {
    setSelectedFileId(fileId);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    if (selectedFileId === null) return;
    try {
      const res = await deleteFiles(selectedFileId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage("File successfully deleted!");
      fetchFilesFromServer();
      if (res.status == 200) {
        try {
          setOpenAlertFetch(true);
          setUpdatingEmbed(true);
          const response = await updateFileEmbedding({
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data);
          setUpdatingEmbed(false);
          setTimeout(() => {
            setOpenAlertFetch(false);
          }, 4000);
        } catch (error: any) {
          const errMsg =
            error.response?.data?.error || "An unknown error occurred";
          console.log("Error updating file embedding:", errMsg);
          setUpdatingEmbed(false);
          setTimeout(() => {
            setOpenAlertFetch(false);
          }, 4000);
          setMessage("Error updating file embedding");
        }
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setMessage("Error deleting file");
    } finally {
      setDeletePopupOpen(false);
    }
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredFiles = uploadedFiles.filter((file) =>
    file.name.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const handleFileClick = (file: { name: string; description: string }) => {
    setInfoFile(file);
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
    setInfoFile(null);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [uploadedFiles]);

  const truncateFilename = (filename: string, maxLength: number) => {
    return filename.length > maxLength
      ? `${filename.substring(0, maxLength)}...`
      : filename;
  };

  // service for file download process
  const handleDownload = async (fileId: number) => {
    try {
      const response = await axiosInstance.get(
        `${apiUrls.USER}/download/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { filename, content } = response.data;
      console.log(response.data);
      const binaryContent = atob(content);
      const arrayBuffer = new ArrayBuffer(binaryContent.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryContent.length; i++) {
        uintArray[i] = binaryContent.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      setMessage("Error downloading file");
    }
  };

  const onCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (company_code) {
      const company_link = `https://${company_code}.${DOMAIN}/`;
      setCompanyLink(company_link);
    }
  }, []);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenAlertFetch(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleCopy = () => {
    navigator.clipboard.writeText("community.techoin.com");
  };

  const [copied, setCopied] = useState(false);
  const textRef = useRef<HTMLInputElement | null>(null);

  const copyLinkHandler = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.value;
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate pagination
  const totalPages = Math.ceil(filteredFiles.length / ITEMS_PER_PAGE);
  const displayedFiles = filteredFiles.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <PrivateLayout>
      <Grid container justifyContent={"center"}>
        <ConfirmationPopup
          open={isDeletePopupOpen}
          deleteButtonClick={handleDelete}
          cancelButtonClick={() => setDeletePopupOpen(false)}
          descriptionBody={"file"}
        />

        <Grid
          container
          width="90%"
          justifyContent={"left"}
          direction={"column"}
        >
          <DashboardTypograpyContainer>Dashboard</DashboardTypograpyContainer>
          <DocumentManagementTypograpyContainer>
            Document management
          </DocumentManagementTypograpyContainer>
        </Grid>

        <GridContainer
          height={"700px"}
          width="90%"
          mt={"25px"}
          borderRadius={"6px"}
        >
          <Grid item xs={12} sm={12} md={6}>
            <GridContainer width={"100%"}>
              <Grid item xs={12}>
                <UploadBox
                  container
                  alignContent={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                  {...getRootProps()}
                >
                  <Grid item>
                    <input {...getInputProps()} />
                  </Grid>
                  <Grid item>
                    <UploadIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      Drag and drop, or select a pdf.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <label htmlFor="file-upload">
                      <StyledWhiteButton
                        type="button"
                        component="span"
                        variant="contained"
                      >
                        Browse
                      </StyledWhiteButton>
                    </label>
                  </Grid>

                  <Grid item>
                    <DocumentSupportTypograpy>
                      <RequiredTypograpyColor>*</RequiredTypograpyColor>Document
                      supported .pdf
                    </DocumentSupportTypograpy>
                  </Grid>
                  {errorMessage && (
                    <Grid item>
                      <Typography variant="body2" color="error">
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                </UploadBox>
              </Grid>
            </GridContainer>
            <DocumentGridMainContainer>
              <Grid item md={12}>
                <DocumentsTypograpy>Documents</DocumentsTypograpy>
              </Grid>
              <DocumentGridSubContainer>
                {/* Search Box */}
                <Grid item md={12} padding="10px 5px">
                  <SearchBar
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                  />
                </Grid>

                {/* PDF List */}
                <GridItem
                  backgroundColor="rgb(239, 239, 239)"
                  border={"0.5px solid #CCD1D9"}
                  padding="5px 5px"
                  height={"75%"}
                  overflowY={"auto"}
                  borderRadius={"8px"}
                  width="100%"
                  position={"relative"}
                  md={12}
                >
                  <List ref={listRef}>
                    {displayedFiles.map((file, index) => (
                      <ListItemContainer key={file.id || index}>
                        <ListItemPdf
                          onClick={() => handleFileClick(file)}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Grid container alignItems={"center"}>
                            <PdfIcon height={"24px"} width={"24px"} />
                            <ListItemTextContainer
                              primary={truncateFilename(file.name, 15)}
                            />
                          </Grid>
                          <ListItemSecondaryAction></ListItemSecondaryAction>
                        </ListItemPdf>
                        <ListItemPdfBox>
                          <DownloadconStyle
                            aria-label="download"
                            onClick={() => handleDownload(file.id)}
                          >
                            <FileDownloadIcon />
                          </DownloadconStyle>
                          <DownloadconStyle
                            aria-label="delete"
                            onClick={() => handleDeleteConfirmation(file.id)}
                          >
                            <Delete />
                          </DownloadconStyle>
                        </ListItemPdfBox>
                      </ListItemContainer>
                    ))}
                    {uploading && selectedFile && (
                      <ListItemContainer>
                        <ListItemPdf
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Grid
                            container
                            alignItems={"center"}
                            direction={"row"}
                          >
                            <Grid item>
                              <PdfIcon height={"24px"} width={"24px"} />
                            </Grid>
                            <Grid
                              item
                              alignItems={"center"}
                              sx={{ width: { xs: "83%" } }}
                            >
                              <TemperListItemTextContainer
                                primary={truncateFilename(
                                  selectedFile.name,
                                  15
                                )}
                              />
                              <LinearProgressWithLabel
                                sx={{ paddingLeft: "10px" }}
                                variant="determinate"
                                value={progress}
                                style={{ width: "95%", background: "#e5e6e8" }}
                              />
                            </Grid>
                          </Grid>
                          <ListItemSecondaryAction></ListItemSecondaryAction>
                        </ListItemPdf>
                        <Box>
                          <IconButton
                            style={{ marginLeft: "10px" }}
                            edge="end"
                            aria-label="delete"
                            onClick={handleCancel}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </Box>
                      </ListItemContainer>
                    )}
                  </List>

                  {/* Pagination Component */}
                  <PaginationStyle
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </GridItem>
              </DocumentGridSubContainer>{" "}
            </DocumentGridMainContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GridContainer
              height="600px"
              width="97%"
              rowGap="16px"
              padding={"16px"}
              borderRadius={"6px"}
              border={"0.5px solid #E6EAF0"}
              paddingTop={{ xs: "10px", md: "0px" }}
              marginLeft={"10px"}
            >
              {/* Subdomain URL Section */}
              <Grid item xs={12}>
                <RightTypograpy variant="body1" gutterBottom></RightTypograpy>
                <Box>
                  <div>
                    <SubdomainTypography variant="body1" gutterBottom>
                      <strong>Subdomain URL</strong>
                      <Tooltip title="This is the URL for the subdomain" arrow>
                        <TooltipIconStyle>
                          {" "}
                          <InfoIconStyle />
                        </TooltipIconStyle>
                      </Tooltip>
                    </SubdomainTypography>
                  </div>
                  <Box display="flex" alignItems="center" gap="8px">
                    <TextField
                      variant="outlined"
                      size="small"
                      value={companyLink}
                      InputProps={{ readOnly: true }}
                      sx={{
                        width: "50%",
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Outfit, sans-serif",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "17.64px",
                          color: "#182A30",
                          "& fieldset": {
                            borderColor: "#D9F1FF",
                          },
                          "&:hover fieldset": {
                            borderColor: "#D9F1FF",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#D9F1FF",
                          },
                          "& .MuiInputBase-input": {
                            textDecoration: "underline",
                          },
                        },
                      }}
                    />
                    <CopyButtonStyle
                      variant="contained"
                      size="small"
                      onClick={handleCopy}
                    >
                      <ContentCopyIcon sx={{ fontSize: "14px" }} /> Copy
                    </CopyButtonStyle>
                  </Box>
                </Box>
              </Grid>

              {/* Script Code Integration Section */}
              <Grid item xs={12}>
                <div>
                  <SubdomainTypography
                    variant="body1"
                    gutterBottom
                  >
                    <strong>Script code integration</strong>
                    <Tooltip title="This is the integration script" arrow>
                      <TooltipIconStyle>
                        {" "}
                        <InfoIconStyle />
                      </TooltipIconStyle>
                    </Tooltip>
                  </SubdomainTypography>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  value={codeSnippet}
                  multiline
                  rows={7}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  inputRef={textRef}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "#E6EAF0",
                      "& fieldset": {
                        borderColor: "#E6EAF0",
                      },
                      "&:hover fieldset": {
                        borderColor: "#E6EAF0",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#E6EAF0",
                      },
                      fontFamily: "Outfit, sans-serif",
                      fontSize: "12px",
                    },
                  }}
                />
               
                  <CopyButtonStyleScript
                    variant="contained"
                    size="small"
                    onClick={copyLinkHandler}
                  >
                    <ContentCopyIcon sx={{ fontSize: "14px" }} />
                    {copied ? "Copied" : "Copy"}
                  </CopyButtonStyleScript>
             
              </Grid>

              {/* QR Code Section */}
              <Grid item xs={12}>
                <div>
                  <SubdomainTypography
                    variant="body1"
                    gutterBottom
                  >
                    <strong>QR Code</strong>
                    <Tooltip title="This is the QR code" arrow>
                      <TooltipIconStyle>
                        {" "}
                        {/* Reduced marginLeft */}
                        <InfoIconStyle />
                      </TooltipIconStyle>
                    </Tooltip>
                  </SubdomainTypography>

                  <QRContainer
                    ref={qrRef}
                  >
                    <GridContainer
                      spacing={2}
                      alignItems="center"
                      width="md: 500px"
                    >
                      <Grid item xs={12} md={6}>
                        <GridContainer paddingBottom="20px" >
                          <ScanQrIcon />
                        </GridContainer>
                        <QrCodeTypography
                          variant="body1"
                          align="left"
                        >
                          Scan QR Code
                          <ScanQrCodeTypography
                          >
                            <p>Scan this QR code to connect with Mobile App</p>
                          </ScanQrCodeTypography>
                        </QrCodeTypography>

                        <StepstoConnectTypography
                          variant="body1"
                          align="left"
                        >
                          steps to connect
                          <ScanQrCodeOl
                          >
                            <li>
                              Open Kamro
                              <AITypograpyColor>AI</AITypograpyColor> app
                              and Login
                            </li>
                            <li>
                              Scan the QR Code to connect with our company
                            </li>
                          </ScanQrCodeOl>
                        </StepstoConnectTypography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Grid>
                          <QRCode value={companyJwt} size={150} />
                        </Grid>
                        <Grid>
                          <strong>{userCompanyName}</strong>
                        </Grid>
                        <GridContainer
                          justifyContent="center"
                          marginTop={"6px"}
                        >
                          <DownloadButton
                            variant="contained"
                           
                            startIcon={<DownloadIcon />}
                            onClick={() => {
                              setQrOpen(true);
                            }}
                          >
                            Download
                          </DownloadButton>
                        </GridContainer>
                      </Grid>
                    </GridContainer>
                  </QRContainer>
                </div>
              </Grid>
            </GridContainer>
          </Grid>
        </GridContainer>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <AppBarStyle
          position="static"
        >
          <Toolbar>
            <Box style={{ flexGrow: 1 }}>
              <UploadFileTypograpy>Upload File</UploadFileTypograpy>
            </Box>
            <CloseCursor onClick={handleClose} />
          </Toolbar>
        </AppBarStyle>{" "}
        <DialogContent>
          <DialogContentBoxStyle >
            <DropDownUploadBox
              container
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <Grid item>
                <PdfIcon height={"66px"} width={"66px"} />
              </Grid>
              <Grid item>
                <Typography>{filename.concat(".pdf")}</Typography>
              </Grid>
            </DropDownUploadBox>
            <Grid container justifyContent={"left"} paddingTop={"10px"}>
              <StyledFormLabel> File Name</StyledFormLabel>
              <StyledTextField
                margin="dense"
                type="text"
                fullWidth
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
                error={!filename}
                helperText={!filename && "Filename required"}
              />
              <StyledFormLabel>Description</StyledFormLabel>
              <StyledTextField
                multiline
                margin="dense"
                minRows={3}
                type="text"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </DialogContentBoxStyle>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpload} disabled={!filename} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={qropen} onClose={handleQrClose}>
        <DialogContent>
          <QRDialogContainer
            ref={qrRef}
          >
            <ScanQRCodeTypography
              variant="h6"
              gutterBottom
            >
              Scan QR Code
            </ScanQRCodeTypography>
            <ScanQRCodeMobileTypography
              variant="body2"
            >
              Scan this QR code to connect with Mobile App.
            </ScanQRCodeMobileTypography>
            <QRCode value={companyJwt} size={120} />{" "}
            <CompanyNameDialogContent
              variant="body1"
            >
              {userCompanyName}
            </CompanyNameDialogContent>
            <StapsConnectTypography
              variant="body2"
              align="left"
            >
              <strong>Steps to connect:</strong>
              <ConnectionOl>
                <li>
                  Open Kamro
                  <AITypograpyColor>AI</AITypograpyColor> app and Login
                </li>

                <li>Scan the QR Code to connect with our company</li>
              </ConnectionOl>
            </StapsConnectTypography>
          </QRDialogContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQrClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleQrDownload()} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={infoOpen}
        onClose={handleInfoClose}
        PaperProps={{  }}
      >
        <DialogTitleStyle>File info</DialogTitleStyle>
        <DialogContentStyle>
          <DialogContentBox
          >
            <InfoFileTypography >{infoFile?.name}</InfoFileTypography>
            <InfoFileTypography
              variant="body2"
              color="textSecondary"
            >
              {infoFile?.description}
            </InfoFileTypography>
          </DialogContentBox>
        </DialogContentStyle>
        <DialogActionStyle>
          <Button onClick={handleInfoClose} color="primary">
            OK
          </Button>
        </DialogActionStyle>
      </Dialog>

      <SnacKBarStyle
        open={openAlertFetch}
        autoHideDuration={updatingEmbed ? null : 3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom" + "right"}
        message={
          updatingEmbed ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size={20} />
              <PdfProcessingTypography>
                Wait for PDF processing
              </PdfProcessingTypography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <CheckCircleOutlineIcon />{" "}
              <PdfProcessingTypography>
                PDF Processed successfully
              </PdfProcessingTypography>
            </Box>
          )
        }
        action={updatingEmbed ? "" : action}
      />
    </PrivateLayout>
  );
};

export default Home;
