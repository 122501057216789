import { styled } from "@mui/system";

import {
  Box,
  Grid,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  ListItem,
  ListItemText,
  Pagination,
  IconButton,
  Button,
  AppBar,
  DialogContent,
  DialogTitle,
  DialogActions,
  Snackbar,
} from "@mui/material";
import type { Theme } from "@mui/material/styles";
import { GridContainer } from "../../components/StyledComponents/Index";
import { spawn } from "child_process";
import { PdfIcon } from "../../assets/svg";
import { Close, Info } from "@mui/icons-material";

export const UploadBox = styled(Grid)({
  textAlign: "center",
  backgroundColor: "#F7F8FA",
  border: "0.5px dashed #CCD1D9",
  borderRadius: "12px",
  height: "200px",
});

export const DropDownUploadBox = styled(Grid)({
  textAlign: "center",
  backgroundColor: "#fafafa",
  border: "2px dashed rgba(1, 164, 255, 1)",
  borderRadius: "12px",
  height: "173px",
});

export const UploadedFilesBox = styled(Box)({
  padding: "20px",
  backgroundColor: "#ffffff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

export const ProfileBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "0.5px solid #01A4FF",
  gap: "17px",
});

export const MenuBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "7px 8px",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "170px",
});

export const DropDownText = styled(Typography)({
  fontWeight: "300",
  fontFamily: "Outfit",
  fontSize: "12px",
  paddingLeft: "7px",
});

export const ListItemPdf = styled(Box)({
  fontWeight: "300",
  fontFamily: "Outfit",
  fontSize: "12px",
  padding: "0px 5px",
  height: "40px",
  width: "100%",
  borderRadius: "4px",
  border: "1px solid #D9F1FF",
  background: "white",
});

export const UploadingpdfProgressBoxContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "10px",
  top: "-10px",
  position: "relative",
});

export const UploadingpdfProgressTypograpyContainer = styled(Box)({
  display: "flex",
  justifyContent: "right",
});

export const UploadingpdfProgressTypograpy = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: "12px",
    ...(theme.typography.body2 as React.CSSProperties),
  })
);

export const LinearProgressContainer = styled(Box)({
  width: "100%",
});

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#01a4ff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#01a4ff",
    color: "#fff",
    fontSize: 12,
    fontFamily: "Outfit",
    padding: "5px",
  },
}));

export const DashboardTypograpyContainer = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: "600",
  color: theme.palette.primary.main,
  marginTop: "80px",
}));
export const DocumentManagementTypograpyContainer = styled(Box)({
  fontSize: "20px",
  fontWeight: "400",
});

export const DocumentsTypograpy = styled(Typography)({
  fontFamily: "Outfit",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: "left",
  color: "#1E1E1E",
  padding: "12px 20px",
});

export const DocumentSupportTypograpy = styled(Typography)({
  fontSize: "12px",
  fontWeight: "400",
  color: "#0088D3",
});

export const ListItemContainer = styled(ListItem)({
  padding: "4px 0px",
});

export const ListItemTextContainer = styled(ListItemText)({
  paddingLeft: "5px",
  "& .MuiTypography-root": { fontSize: "14px" },
});
export const ListItemPdfBox = styled(Box)({
  display: "flex",
});

export const DocumentGridMainContainer = styled(GridContainer)({
  backgroundColor: "FBFDFF",
  borderRadius: "6px",
  marginTop: "20px",
  boxShadow: "0px 0px 4px 1px #CCD1D91F",
  border: "0.5px solid #E6EAF0",
  width: "100%",
});

export const DocumentGridSubContainer = styled(GridContainer)({
  backgroundColor: "white",
  borderRadius: "6px",
  height: "330px",
  padding: "5px 10px",
  borderTop: "0.5px solid #E6EAF0",
});

export const PaginationStyle = styled(Pagination)({
  position: "absolute",
  bottom: "5px",
  right: "15px",
});

export const RequiredTypograpyColor = styled("span")({
  color: "red",
});

export const DownloadconStyle = styled(IconButton)({
  padding: "4px",
});

export const RightTypograpy = styled(Typography)({
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17.64px",
  color: "#182A30",
});

export const SubdomainTypography = styled(Typography)({
  fontFamily: "Outfit, sans-serif",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17.64px",
  color: "#182A30",
  display: "flex",
  alignItems: "center",
});

export const TooltipIconStyle = styled(IconButton)({
  padding: "4px",
  marginLeft: "4px",
});

export const InfoIconStyle = styled(Info)({
  fontSize: "16px",
  color: "#717171",
});

export const CopyButtonStyle = styled(Button)({
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "17.64px",
  color: "#717171",
  backgroundColor: "transparent",
  border: "1px solid #CCD1D9",
  display: "flex",
  alignItems: "center",
  gap: "2px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#0088D3",
    borderColor: "#0088D3",
    color: "#fff",
  },
});

export const CopyButtonStyleScript = styled(Button)({
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "12px",
  color: "#717171",
  backgroundColor: "transparent",
  border: "1px solid #CCD1D9",
  display: "flex",
  alignItems: "center",
  gap: "2px",
  textTransform: "none",
  marginLeft: "auto",
  marginTop: "8px",
  "&:hover": {
    backgroundColor: "#0088D3",
    borderColor: "#0088D3",
    color: "#fff",
  },
});

export const QRContainer = styled("div")({
  padding: "20px",
  textAlign: "center",
});

export const QrCodeTypography = styled(Typography)({
  fontFamily: "Outfit",
  fontWeight: 500,
  fontSize: "20px",
  color: "#1E1E1E",
  marginTop: "-10px",
});

export const ScanQrCodeTypography = styled("ol")({
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "12px",
  color: "#717171",
  paddingLeft: "1px",
});

export const StepstoConnectTypography = styled(Typography)({
  fontFamily: "Outfit",
  fontWeight: 500,
  fontSize: "12px",
  color: "#717171",
  textAlign: "left",
  marginTop: "30px",
});

export const ScanQrCodeOl = styled("ol")({
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "12px",
  color: "#1E1E1E",
  margin: 0,
  paddingLeft: "20px",
});

export const AITypograpyColor = styled("span")({
  color: "#F15A5A",
});

export const DownloadButton = styled(Button)({
  backgroundColor: "#F15A5A",
  color: "#fff",
  background: "linear-gradient(45deg, #F15A5A, #FFB700)",
  "&:hover": {
    background: "linear-gradient(45deg, #FFB700, #F15A5A)",
  },
  textTransform: "none",
  fontWeight: "300",
  "& .MuiButton-startIcon": {
    color: "#fff",
  },
});

export const AppBarStyle = styled(AppBar)({
  background: "#ffffff",
  border: "0.5px solid transparent",
  borderImageSource:
    "linear-gradient(to bottom, #FFB700, #182A30 46.5%, #F15A5A)",
  borderImageSlice: 1,
  borderImageWidth: "1", // Use '1' without unit when specifying for border image
  borderImageOutset: 0,
  borderImageRepeat: "stretch",
  height: "45px",
  justifyContent: "center",
});

export const UploadFileTypograpy = styled(Typography)({
  fontFamily: "Outfit",
  color:"black"
});

export const CloseCursor = styled(Close)({
  cursor: "pointer",
  color:"black"
});

export const DialogContentBoxStyle = styled(DialogContent)({
  textAlign: "center",
  padding: "16px",
});

export const QRDialogContainer = styled("div")({
  padding: "15px",
  textAlign: "center",
  background: "white",
  borderRadius: "8px",
  width: "320px",
  margin: "auto",
});

export const ScanQRCodeTypography = styled(Typography)({
  fontFamily: "Outfit",
  fontWeight: "bold",
  color: "#1B1B1B",
  fontSize: "16px",
  paddingBottom: "8px",
});

export const ScanQRCodeMobileTypography = styled(Typography)({
  fontFamily: "Outfit",
  color: "#6F6F6F",
  fontSize: "12px",
  marginBottom: "15px",
});

export const CompanyNameDialogContent = styled(Typography)({
  fontFamily: "Outfit",
  fontWeight: "bold",
  fontSize: "14px",
  marginTop: "8px",
  color: "#1B1B1B",
});

export const StapsConnectTypography = styled(Typography)({
  fontFamily: "Outfit",
  color: "#6F6F6F",
  fontSize: "12px",
  marginTop: "15px",
});

export const ConnectionOl = styled("ol")({
  paddingLeft: "15px", 
  margin: "8px 0" 
});

export const DialogTitleStyle = styled(DialogTitle)({
   width: "600px"
});

export const DialogContentStyle = styled(DialogContent)({
   textAlign: "center"
});

export const DialogContentBox = styled(Box)({
  padding: "16px",
});

export const InfoFileTypography = styled(Typography)({
 marginTop: "10px"
});

export const DialogActionStyle = styled(DialogActions)({
   width: "800px" 
 });

 export const SnacKBarStyle = styled(Snackbar)({
 minWidth: "100px"
});

export const PdfProcessingTypography = styled(Typography)({
  paddingLeft: "20px" 
 });

export const TemperListItemTextContainer = styled(ListItemText)({
  paddingLeft: "10px",
  top: "5px",
  position: "relative",
  "& .MuiTypography-root": { fontSize: "14px" },
});
