import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "../../Cores/Link/index";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import {
  apiUrls,
  help,
  regularExpressions,
  success,
} from "../../../Utilities/properties";
import { logIn } from "../../../services/auth.services";
import { GridContainer, GridItem } from "../../StyledComponents/Index";
import {
  FormHeadingTypography,
  FormTextTypography,
  StyledField,
  StyledFormLabel,
  StyledButton,
  ErrorGrid,
  ForgorGrid,
  // StyledErrorMessage as ErrorMessage,
} from "../../Cores/FormCores/style";
import { Box } from "@mui/system";
import { enqueueSnackbar } from 'notistack';


// const ValidationErrorHandler = ({
//   setErrorMsg,
// }: {
//   setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
// }) => {
//   const formik = useFormikContext<any>();

//   // useEffect(() => {
//   //   if (formik.touched.email && formik.errors.email) {
//   //     const errorMessage = Array.isArray(formik.errors.email)
//   //       ? formik.errors.email[0]
//   //       : formik.errors.email;
//   //     setErrorMsg(errorMessage as string);
//   //   } else if (formik.touched.password && formik.errors.password) {
//   //     const errorMessage = Array.isArray(formik.errors.password)
//   //       ? formik.errors.password[0]
//   //       : formik.errors.password;
//   //     setErrorMsg(errorMessage as string);
//   //   } else {
//   //     setErrorMsg("");
//   //   }
//   // }, [formik.touched, formik.errors]);

//   return null;
// };
export default function LoginForm() {
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(help.INVALID_EMAIL_ADDRESS)
      .matches(regularExpressions.EMAIL_REGEX, help.INVALID_EMAIL_ADDRESS)
      .required("Email " + help.VALUE_REQUIRED),
    password: Yup.string().required("Password " + help.VALUE_REQUIRED),
  });

  const onSubmit = async (values: any) => {
    const { email, password } = values;

    try {
      const processedEmail = email.toLowerCase().trim();      

      const logginResponse = await logIn({
        email_address: processedEmail,
        password: password,
      });
      const { access_token, refresh_token } = logginResponse.data;

      localStorage.setItem("token", access_token);
      localStorage.setItem('refresh_token', refresh_token);
      console.log(logginResponse.data);
      enqueueSnackbar(success.LOGIN_SUCCESS, {variant:"success"});
      navigate(apiUrls.DASHBOARD);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        setErrorMsg(errMsg);
      }else {
        console.error('Error Logging user:', error);
        enqueueSnackbar('Failed to login user. Please try again later.', {variant:"error"});
      }
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            {/* <ValidationErrorHandler setErrorMsg={setErrorMsg} /> */}
            <GridContainer>
              <GridItem md={12}>
                <FormHeadingTypography>Sign in</FormHeadingTypography>
              </GridItem>
              <ErrorGrid md={12}>
                {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              </ErrorGrid>
              <GridItem md={12} sx={{ marginBottom: "8px" }}>
                <StyledFormLabel> Email</StyledFormLabel>
              </GridItem>
              <GridItem md={12} sx={{ height: "80px" }}>
                <Field
                  name="email"
                  type="email"
                  style={
                    {
                      input:{fontFamily:'Outfit'}
                    }
                  }
                  component={StyledField}
                  error={
                    (formik.touched.email && Boolean(formik.errors.email)) ||
                    errorMsg
                  }
                  helperText={<ErrorMessage name="email" />}
                  onFocus={() => setErrorMsg("")}
                  
                />
              </GridItem>
              <GridItem md={12} sx={{ marginBottom: "8px" }}>
                  <StyledFormLabel> Password</StyledFormLabel>
              </GridItem>
              <GridItem md={12} sx={{ height: "80px" }}>
                <Field
                  name="password"
                  type="password"
                  component={StyledField}
                  error={
                    (formik.touched.password &&
                      Boolean(formik.errors.password)) ||
                    errorMsg
                  }
                  helperText={<ErrorMessage name="password" />}
                  onFocus={() => setErrorMsg("")}
                />
              </GridItem>

              <GridItem md={12}>
                <StyledButton type="submit">Continue</StyledButton>
              </GridItem>

              <GridItem md={12} sx={{ marginBottom: "8px" }}>
                  <ForgorGrid xs={12} md={12}>
                    <Link href={apiUrls.FORGOT_PASSWORD}>Forgot Password?</Link>
                  </ForgorGrid>
              </GridItem>

              <GridItem md={12}>
                <FormTextTypography>
                  Don't have an account?{" "}
                  <Link href={apiUrls.REGISTER}>Sign up</Link>
                </FormTextTypography>
              </GridItem>
            </GridContainer>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
