import styled from "@emotion/styled";
import {
  Button,
  ButtonProps,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  Box,
  Avatar,
  Divider,
  IconButton,
  Alert,
} from "@mui/material";
import {
  GridContainer,
  GridItem,
} from "../../components/StyledComponents/Index";

export const StyledButton3 = styled(Button)`
  height: Hug (40px) px;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #01a4ff;
  background: #ffffff;
  border: 1px solid #a7a7a74d;
  box-shadow: 0px 2px 4px 0px #1e1e1e29;
  text-transform: none;
`;

export const StyledButton4 = styled(Button)`
  height: Hug (40px) px;
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  background: linear-gradient(182.29deg, #01a4ff 1.82%, #008cdb 97.99%);
  border: 1px solid #a7a7a74d;
  box-shadow: 0px 2px 4px 0px #1e1e1e29;
  text-transform: none;
`;




export const CompanyNameText = styled(Typography)`
  font-family: Outfit;
  font-weight: 600;
  text-align: left;
  margin: 0px;
  color: #1e1e1e;
  fontsize: "20px";
`;

export const DetailFormText = styled(Typography)`
  font-family: Outfit;
  font-weight: 400;
  text-align: left;
  margin: 0px;
  padding-bottom: 5px;
  color: #717171;
`;
export const CompanyNameTextSTyle = styled(Typography)`
font-weight: 600;
text-align: left;
margin: 0px;
color: #1E1E1E;
font-size: 32px;
padding-top: 30px;
padding-left: 50px;
`;

export const StyledModal = styled((props: ModalProps) => (
  <Modal sx={{ display: "flex", p: "15px", zIndex: "5000" }} {...props} />
))({});
export const Container = styled((props: PaperProps) => (
  <Paper
    elevation={1}
    sx={{
      border: "none",
      outline: "none",
      maxWidth: "460px",
      width: "100%",
      m: "auto",
      borderRadius: "30px",
      p: { xs: "20px 30px", sm: "20px 55px", md: "20px 85px" },
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      alignItems: "center",
      zIndex: "5000",
    }}
    {...props}
  />
))({});
export const Heading = styled((props: TypographyProps) => (
  <Typography
    fontSize={{ xs: "15px", sm: "18px" }}
    textAlign="center"
    fontWeight={600}
    {...props}
  />
))({});
export const SubHeading = styled((props: TypographyProps) => (
  <Typography
    fontSize="12px"
    fontWeight={700}
    color="#929292"
    whiteSpace="nowrap"
    {...props}
  />
))({});
export const DeleteButton = styled((props: ButtonProps) => (
  <Button
    color="error"
    variant="contained"
    sx={{
      width: "145px",
      height: "37px",
      fontSize: "12px",
      fontWeight: 600,
      borderRadius: "30px",
      backgroundColor: "#FE6666",
      "&:hover": {
        backgroundColor: "#FE6666",
        boxShadow: "none",
      },
    }}
    {...props}
  />
))({});
export const CancelButton = styled((props: ButtonProps) => (
  <Button
    variant="text"
    sx={{
      fontSize: "14px",
      fontWeight: 600,
      backgroundColor: "transparent",
      color: "#024B8F",
      "&:hover": {
        color: "#024B8F",
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    }}
    {...props}
  />
))({});
export const FlexRow = styled((props: StackProps) => (
  <Stack
    flexDirection="row"
    gap="3px"
    justifyContent="center"
    alignItems="center"
    {...props}
  />
))({});

export const TopBoxColor = styled(Box)({
  background: "linear-gradient(90deg, #FFF2FD 0%, #F3F0FF 51.5%, #E8F9FF 100%)",
  height: "200px",
});

export const MainBox = styled(Box)({
  justifyContent: "center",
});

export const MarginBox = styled(Box)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
});

export const LogoDesignBox = styled(Box)({
  position: "absolute",
  top: "-50px",
  left: "10%",
  display: "flex",
  alignItems: "center",
});

export const AvatarBox = styled(Avatar)({
  height: "160px",
  width: "160px",
  border: "4px solid #FFFFFF",
  boxShadow: "0px 2px 5px 0px #0000001F",
});

export const CompanyTextBox = styled(Avatar)({
  height: "160px",
  width: "160px",
  border: "4px solid #FFFFFF",
  boxShadow: "0px 2px 5px 0px #0000001F",
});

export const CustomDivider = styled(Divider)({
  border: "0.5px solid #00000033",
});

export const AvatarBoxLogo = styled(Avatar)({
  height: "96px",
  width: "96px",
  border: "4px solid #FFFFFF",
  boxShadow: "0px 2px 5px 0px #0000001F",
});

export const IconButtonStyle = styled(IconButton)({
  position: "absolute",
  top: "20px",
  left: "92px",
  transform: "translate(-50%, -50%)",
  zIndex: 10,
  backgroundColor: "white",
  borderRadius: "50%",
  padding: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
});

export const IconButtonStyleEdit = styled(IconButton)({
  position: "absolute",
  top: "-8px",
  right: "0px",
});

export const PasswordIconButtonStyle = styled(IconButton)({
  marginLeft: "8px",
});

export const MainGridContainer = styled(GridContainer)({
  width: "90%",
});

export const TextfieldGridContainer = styled(GridContainer)({
  position: "relative",
});

export const AlertStyle = styled(Alert)({
  marginTop: "10px"
});
