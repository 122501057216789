// style.tsx
import { styled,Button,Typography,Link, Box } from '@mui/material';
import { Grid } from '@mui/material';
import { GridContainer, GridItem } from '../../components/StyledComponents/Index';
import BackgroundImage from "../../assets/svg/background-image.png";


export const PublicLayoutContainer = styled(GridContainer)(({ theme }) => ({
  height: "100vh",
  width: '100%',
}));

export const LayoutImageContainer = styled(GridContainer)(({ theme }) => ({
  height: "100vh",
  // background: "radial-gradient(132.65% 173.85% at 70.04% 95.21%, #110D28 26.5%, #A2123D 100%)",
  
}));

export const LayoutImageItem = styled(GridItem)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  background: `url(${BackgroundImage}) no-repeat center center fixed`,
  backgroundSize: 'cover',
  backgroundPosition: '-441px -3px',
}));

export const LayoutFormContainer = styled(GridContainer)(({ theme }) => ({
}));

export const LayoutFormItem = styled(GridItem)(({ theme }) => ({
  display: "flex",
  alignItems:'center',
  flexDirection: "column",
}));

export const FormLabelStyled = styled('div')(({ theme }) => ({
  fontWeight: "500",
  fontSize: "16px",
  fontFamily: "Outfit",
  color: "inherit",
}));

export const SignInButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  borderRadius: "24px",
  height: "52px",
  border: "1px solid rgba(241, 90, 90, 0.2)",
  boxShadow: "0px 4px 4px 0px rgba(241, 90, 90, 0.12)",
  textTransform: "inherit",
}));

export const SignInTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "32px",
  marginBottom: "30px",
}));

export const FormContainer = styled(Box)({
  height:'100vh',
  display: "flex",
  alignItems: "center",
  flexDirection: 'column',
  width: "100%",
  maxWidth: "360px",
});

export const LinkStyled = styled(Link)(({ theme }) => ({
  color: "inherit",
}));

export const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  textAlign: "right",
}));
