import { VisibilityOff, Visibility } from "@mui/icons-material";
import { styled, Typography, FormLabel, TextField } from "@mui/material";
import { StyledField as Field, StyledButton as Button } from "./index";
import { GridItem } from "../../StyledComponents/Index";
import { ErrorMessage } from "formik";

export const FormHeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: "32px",
  
}));

export const FormTextTypography = styled(Typography)(({ theme }) => ({
  marginTop: "14px",
  fontSize: "14px",
  fontWeight: "300",
  lineHeight: "17.64px",
  textAlign: "center",
  variant: "body2",
  color: "#717171",
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "16px",
  fontFamily: "Outfit",
  color: "#1E1E1E",
}));

export const StyledFormLabel2 = styled(FormLabel)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "16px",
  fontFamily: "Outfit",
  color: "#1E1E1E",
  margin: "0px",
}));

export const OffVisibility = styled(VisibilityOff)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const OnVisibility = styled(Visibility)(({ theme }) => ({
  color: "primary",
}));

export const StyledField = styled(Field)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid rgba(217, 241, 255, 1)",
      borderRadius: "12px",
    },
  },
  backgroundColor: "rgba(252, 254, 255, 1)",
  borderRadius: "12px",
  marginBottom: "16px",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid rgba(217, 241, 255, 1)",
      borderRadius: "12px",
    },
  },
  backgroundColor: "rgba(252, 254, 255, 1)",
  borderRadius: "12px",
  marginBottom: "16px",
}));

export const StyledButton = styled(Button)({
  marginTop: "13px",
  borderRadius: "12px",
  height: "52px",
  boxShadow: "0px 4px 10px 0px #FF8686 inset",
  textAlign: "center",
  background: "linear-gradient(270.06deg, #F15A5A 0.04%, #FFB700 100.1%)",

  marginBottom: "20px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: "500",
});


export const StyledWhiteButton = styled(Button)({
  marginTop: "13px",
  borderRadius: "6px",
  height: "30px",
  color: "black",
  textAlign: "center",
  background: "#FFFFFF",
  textTransform: "none",
  marginBottom: "12px",
  border: "1px solid #CCD1D9",
  boxShadow: "0px 0px 4px -1px #0000000F",
  fontSize: "14px",
  fontWeight: "500",
   width: "86px",
  "&:hover": {
    backgroundColor: "#0088D3", 
    borderColor: "#0088D3", 
    color: "#fff", 
  },
});

export const ErrorGrid = styled(GridItem)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "10px",
}));

export const FieldGrid = styled(GridItem)(({ theme }) => ({
  marginBottom: "10px",
}));

export const ForgorGrid = styled(GridItem)(({ theme }) => ({
  textAlign: "left",
}));

export const StyledErrorMessage = styled(ErrorMessage)(({ theme }) => ({
  color: "blue",
  marginTop: "4px", 
  marginBottom: "20px", 
}));
