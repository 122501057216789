import { FILE } from "dns";

export const filePaths = {
    ACTIVITY: '/activity/',
    USER_PROFILE:'/user-profile/',
  };
export const DOMAIN = 'kamroai.com';
export const BASEURL = 'https://ap.kamroai.com';

export const apiUrls = {
    BASEURL: 'https://ap.kamroai.com',
    USER: '/api',
    AUTH: '/api/auth',
    REGISTER: '/register',
    LOG_IN: '/login',
    LOG_OUT: '/logout',
    VERIFY_EMAIL_SUCCESS: '/verify-email-success',
    VERIFY_EMAIL: '/verify-email',
    RESEND_OTP: '/resend-otp',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    CREATE_PASSWORD: '/create-new-password',
    REFRESH_ACCESS_TOKEN: '/refresh-access-token',
    MEMBER: '/member',
    USER_DETAILS: '/user-home',
    USER_CHECK:'/user-check',
    USER_CHECK2:'/user-check2',
    FILE:'/files',
    UPLOAD:'/upload',
    ADD_EMBED:'/embed',
    UPDATE_EMBED:'/update-embedding',
    UPDATE:'/update-profile',
    DELETE:'/delete',
    DASHBOARD:'/dashboard',
    PROFILE:'/profile',
    GENERATE:'/generate-url'
  };

  export const regularExpressions = {
    EMAIL_REGEX: /^[a-zA-Z0-9](.?)+@[a-zA-Z0-9]+.[A-Za-z]+$/,
    PHONE_REGEX:
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
    DECIMAL_REGEX: '^[1-9]{1,3}(,[0-9]{3})*(.[0-9]{1,2})?$'
  };

  export const help = {
    PASSWORD_HELP:
      'Must Contain 8 or more characters, with a mix of uppercase, lowercase letters, numbers &symbols(!, @, #, $, %)',
    PASSWORD_MISMATCH: 'These passwords didn’t match',
    INVALID_CONTACT_NUMBER: 'Invalid contact number!',
    INVALID_EMAIL_ADDRESS: 'Invalid email address!',
    TOO_SHORT: 'Value is too short',
    VALUE_REQUIRED: 'cannot be blank',
    ENTER_EMAIL: 'Please enter the email address',
    CHECK_YOUR_EMAIL: 'Summary Sent to an email. Please check your email!',
    FILE_FORMAT_UNSUPPORTED: 'Unsupported  Format!',
    FILE_SIZE_BIG: 'File Size is too large!',
  };

  export const success = {
    LOGIN_SUCCESS: 'Logged in Success',
    RESET_PASSWORD_SUCCESS:'Reset Password Success',
    
  };
  export const message = {
    LOGOUT_SUCCESS:
      'Logged Out Success',
  };
  export const key = {
    SECRET_KEY:  'g1F%h#dA9z*Yp3L2@kN!bP5&X',
  };

  export const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf'
  ];