import { Button, ButtonProps, Grid, styled } from "@mui/material";

// GridContainer styled component
export const GridContainer = styled(
  (props: {
    children?: React.ReactNode; // Add children prop
    justifyContent?: string;
    backgroundColor?: string;
    width?: string;
    minHeight?: any;
    sx?: any;
    p?: any;
    rowSpacing?: any;
    columnSpacing?: any;
    borderRadius?: any;
    margin?: any;
    mt?: any;
    mb?: any;
    textAlign?: any;
    maxWidth?: any;
    height?: any;
    maxHeight?: any;
    direction?: any;
    boxShadow?: any;
    border?: any;
    padding?: any;
    bordertop?: any;
    sm?: any;
    md?: any;
    xs?: any;
    lg?: any;
    rowGap?: any;
    paddingTop?: any;
    marginLeft?: any;
    spacing?: any;
    alignItems?: any;
    paddingBottom?: any;
    marginTop?: any;
  }) => <Grid container {...props} />
)({});

// GridItem styled component
export const GridItem = styled(
  (props: {
    children?: React.ReactNode; // Add children prop
    backgroundColor?: string;
    width?: string;
    maxWidth?: string;
    height?: any;
    minWidth?: string;
    sx?: any;
    p?: any;
    pt?: any;
    m?: any;
    mr?: any;
    ml?: any;
    mb?: any;
    margin?: any;
    xs?: any;
    md?: number;
    rowSpacing?: any;
    columnSpacing?: any;
    borderRadius?: any;
    textAlign?: any;
    component?: any;
    maxHeight?: any;
    minHeight?: any;
    sm?: any;
    lg?: any;
    border?: any;
    padding?: any;
    overflowY?: any;
    position?: any;
    paddingTop?: any;
  }) => <Grid item xs={12} md={6} {...props} />
)({});

export const StyledButton = styled(
  (props: ButtonProps & { type?: React.HTMLInputTypeAttribute }) => {
    const { type, ...otherProps } = props;

    return (
      <Button
        {...otherProps}
        type={type}
        fullWidth
        variant="contained"
        color="primary"
      />
    );
  }
)({});
