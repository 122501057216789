import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  TextField,
} from "@mui/material";
import {
  CloudUpload,
  Delete,
  Close,
  Settings,
  ExitToApp,
} from "@mui/icons-material";
import { fontWeight, styled } from "@mui/system";
import logoImage from "../../assets/svg/logo.png";
import { LogoutIcon, SettingIcon, UploadIcon } from "../../assets/svg";
import theme from "../../components/theme";
import { StyleButton_2 } from "../../components/Forms/OTP/style";
import { StyledButton } from "../../components/Cores/FormCores/style";
import { UserDetails } from "../../services/auth.services";
import { Navigate, useNavigate } from "react-router-dom";
import { apiUrls, DOMAIN, message, success } from "../../Utilities/properties";
import { useAvatarContext } from "../../context/AvatarContext";
import { enqueueSnackbar } from "notistack";
import BackgroundImage from "../../assets/svg/background-image.png";
import "../../css/style.css";

const UploadBox = styled(Grid)({
  textAlign: "center",
  marginLeft: "30px",
  backgroundColor: "#fafafa",
  border: "2px dashed rgba(1, 164, 255, 1)",
  borderRadius: "12px",
  height: "590px",
  width: "88%",
});

const UploadedFilesBox = styled(Box)({
  padding: "20px",
  backgroundColor: "#ffffff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const ProfileBox = styled(Box)({
  minWidth: "120px",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "8px",
  cursor: "pointer",
  gap: "17px",
});

const MenuBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "7px 8px",
  borderRadius: "5px",
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "170px",
});

const DropDownText = styled(Typography)({
  fontWeight: "300",
  fontFamily: "Outfit",
  fontSize: "12px",
  paddingLeft: "7px",
});


interface PrivateLayoutProps {
  children: React.ReactNode;
}
interface Admin {
  firstName: string;
  emailAddress: string;
}
interface Company {
  companyName: string;
  logo: string;
}
const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  ...props
}) => {
  const {
    userAvatar,
    setUserAvatar,
    userHeaderName,
    setUserHeaderName,
    userCompanyName,
    setUserCompanyName,
  } = useAvatarContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [admin, setAdmin] = useState<Admin>({
    firstName: "",
    emailAddress: "",
  });
  const [company, setCompany] = useState<Company>({
    companyName: "",
    logo: "",
  });
  const token = localStorage.getItem("token");
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(apiUrls.LOG_IN);
    }
    const fetchAdmin = async () => {
      console.log(token);
      try {
        const response = await UserDetails({
          headers: { Authorization: `Bearer ${token}` },
        });

        setAdmin(response.data.user);
        setUserAvatar(response.data.company.logo);
        setUserHeaderName(response.data.user.firstName);
        setUserCompanyName(response.data.company.companyName);
        setCompany(response.data.company);
        localStorage.setItem(
          "company_code",
          response.data.company.companyCode
        );
        setPreview(response.data.company.logo);
      } catch (error: any) {
        if (error.response && error.response.data) {
          const errMsg = error.response.data.error;
          console.log("error:", errMsg);
        }
      }
    };

    fetchAdmin();
  }, [userAvatar, userHeaderName]);

  console.log(userAvatar);
  console.log(userHeaderName);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <AppBar
        position="fixed"
       
        style={{
          background: "#ffffff",
          borderBottom: "0.5px solid transparent",
          borderImageSource:
            "linear-gradient(to bottom, #FFB700, #182A30 46.5%, #F15A5A)",
          borderImageSlice: 1,
          borderImageWidth: "1", // Use '1' without unit when specifying for border image
          borderImageOutset: 0,
          borderImageRepeat: "stretch",
          
        }}
      >
        <Toolbar>
          <Box style={{ flexGrow: 1 }}>
            <img
              src={logoImage}
              height={"60px"}
              onClick={() =>
                (window.location.href = `https://www.`+DOMAIN)
              }
              style={{ cursor: "pointer" }}
            />
          </Box>
          <ProfileBox onClick={handleProfileMenuOpen}>
            <Avatar
              style={{ height: "34px", width: "34px" }}
              src={
                `${apiUrls.BASEURL}/uploads/${userAvatar}`
              }
            />
            <Typography
              style={{
                fontWeight: "500",
                fontFamily: "Outfit",
                color: "black",
              }}
            >
              {userHeaderName}
            </Typography>
          </ProfileBox>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
            PaperProps={{
              style: {
                
                border: "0.5px solid transparent",
                borderImageSource:
                  "linear-gradient(to bottom, #FFB700, #182A30 46.5%, #F15A5A)",
                borderImageSlice: 1,
                borderImageWidth: "1", // Use '1' without unit when specifying for border image
                borderImageOutset: 0,
                borderImageRepeat: "stretch",
                marginLeft: "-8px",
                marginTop: "3px",
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              rowGap={1}
            >
              <Avatar
                style={{ height: "45px", width: "45px" }}
                src={`${apiUrls.BASEURL}/uploads/${userAvatar}`}
              />
              <Typography style={{ fontWeight: "500", fontFamily: "Outfit" }}>
                {userHeaderName}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "16px" }}>
              <MenuItem sx={{ padding: "0px", margin: "8px" }}>
                <MenuBox onClick={() => navigate(apiUrls.PROFILE)}>
                  <SettingIcon />
                  <DropDownText>Profile settings</DropDownText>
                </MenuBox>
              </MenuItem>
              <MenuItem sx={{ padding: "0px", margin: "8px" }}>
                <MenuBox
                  onClick={() => {
                    localStorage.removeItem("token");
                    navigate(apiUrls.LOG_IN);
                    enqueueSnackbar(message.LOGOUT_SUCCESS, {
                      variant: "success",
                    });
                  }}
                >
                  <LogoutIcon />
                  <DropDownText>Log out</DropDownText>
                </MenuBox>
              </MenuItem>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
          {children} 
    </Grid>
  );
};

export default PrivateLayout;