import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const SearchBar = (props: Props) => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search documents"
      onChange={props.onChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          width: "300px",
          height: "50px",
          "& fieldset": {
            border: "0.5px solid #CCD1D9",
            borderRadius: "6px",
          },
        },
        color: "#717171",
        fontSize: "12px",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ height: "20px", width: "20px", color: "#717171" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};