import React, { useEffect, useState, useRef } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Edit as EditIcon, Password } from "@mui/icons-material";
import {
  AlertStyle,
  AvatarBox,
  AvatarBoxLogo,
  CompanyNameText,
  CompanyNameTextSTyle,
  CustomDivider,
  DetailFormText,
  IconButtonStyle,
  IconButtonStyleEdit,
  LogoDesignBox,
  MainBox,
  MainGridContainer,
  MarginBox,
  PasswordIconButtonStyle,
  TextfieldGridContainer,
  TopBoxColor,
} from "./style";
import { StyledFormLabel } from "../../components/Cores/FormCores/style";
import {
  GridContainer,
  GridItem,
} from "../../components/StyledComponents/Index";
import UploadTextField from "../../components/Forms/RegisterForm/last/Logo";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { apiUrls, help, regularExpressions } from "../../Utilities/properties";
import {
  InputField2,
  PhoneField2,
} from "../../components/Forms/RegisterForm/last/InputFIeld";
import { UpdateProfile, UserDetails } from "../../services/auth.services";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Utilities/axiosInstance";
import { useAvatarContext } from "../../context/AvatarContext";
import { CountrySelect2 } from "../../components/Forms/RegisterForm/last/CountrySelect";
import { enqueueSnackbar } from "notistack";
import SaveIcon from "@mui/icons-material/Save";

// Updated type definition for `isEdited`
type EditedFields = {
  name: boolean;
  phoneNumber: boolean;
  country: boolean;
  logo: boolean;
  companyName: boolean;
  Password: boolean;
};

const ProfileContainer = styled(Container)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  backgroundColor: "white",
  borderRadius: theme.shape.borderRadius,
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

interface Admin {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

interface Company {
  companyName: string;
  logo: File | null;
  companyRegNo: string;
  companyCode: string;
  companyCountry: string;
}

const ProfileSettings: React.FC = () => {
  const { setUserAvatar, setUserHeaderName } = useAvatarContext();
  const [admin, setAdmin] = useState<Admin>({
    firstName: "",
    emailAddress: "",
    lastName: "",
    phoneNumber: "",
  });
  const [company, setCompany] = useState<Company>({
    companyName: "",
    companyRegNo: "",
    companyCode: "",
    companyCountry: "",
    logo: null,
  });
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editState, setEditState] = useState({
    name: false,
    phoneNumber: false,
    country: false,
    logo: false,
    email: false,
    companyName: false,
    Password: false,
  });
  const [isEdited, setIsEdited] = useState<EditedFields>({
    name: false,
    phoneNumber: false,
    country: false,
    logo: false,
    companyName: false,
    Password: false,
  });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const countryRef = useRef(null);

  const fetchAdmin = async () => {
    try {
      const response = await UserDetails({
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdmin(response.data.user);
      setCompany(response.data.company);
      setPreview(`${apiUrls.BASEURL}/uploads/${response.data.company.logo}`);
      setIsLoading(false);
    } catch (error: any) {
      enqueueSnackbar("Error in fetching admin details", { variant: "error" });
    }
  };

  useEffect(() => {
    if (!token) {
      navigate(apiUrls.LOG_IN);
    }
    fetchAdmin();
  }, [token]);

  const allSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required("Name " + help.VALUE_REQUIRED),
    phoneNumber: yup.string().required("Phone number " + help.VALUE_REQUIRED),
    email: yup
      .string()
      .email(help.INVALID_EMAIL_ADDRESS)
      .matches(regularExpressions.EMAIL_REGEX, help.INVALID_EMAIL_ADDRESS)
      .required("Email " + help.VALUE_REQUIRED),
    country: yup.string().required("Country " + help.VALUE_REQUIRED),
    password: yup.string().when("newPassword", {
      is: (val: string | null) => !!val,
      then: (allSchema: yup.StringSchema) =>
        allSchema.required("Password is required when New Password is set"),
      otherwise: (allSchema: yup.StringSchema) => allSchema.notRequired(),
    }),
    newPassword: yup
      .string()
      .matches(regularExpressions.PASSWORD_REGEX, help.PASSWORD_HELP),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), ""], help.PASSWORD_MISMATCH),
  });

  const onSubmit = async (values: any) => {
    setEditState({
      name: false,
      phoneNumber: false,
      country: false,
      logo: false,
      email: false,
      companyName: false,
      Password: false,
    });

    try {
      const formData = new FormData();
      const trimName=values.name.trim();
      formData.append("first_name", trimName.split(" ")[0]);
      formData.append("last_name", trimName.split(" ")[1]);
      formData.append("phone_number", values.phoneNumber.trim());
      formData.append("company_name", values.companyName.trim());
      formData.append("company_country", values.country.trim());
      formData.append("new_password", values.newPassword.trim());
      formData.append("current_password", values.password.trim());
      formData.append("logo", values.companyLogo);

      const response = await axiosInstance.put(
        `${apiUrls.USER}/update-profile`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      enqueueSnackbar(response.data.message, { variant: "success" });
      setUserAvatar(values.companyLogo);
      setUserHeaderName(values.firstName);
      fetchAdmin();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Formik
      initialValues={{
        name: `${admin.firstName} ${admin.lastName}`,
        phoneNumber: admin.phoneNumber,
        email: admin.emailAddress,
        companyName: company.companyName,
        companyLogo: null,
        companyRegistrationNo: company.companyRegNo,
        companyCode: company.companyCode,
        country: company.companyCountry,
        password: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={allSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <MainBox>
            <TopBoxColor></TopBoxColor>
            <MarginBox>
              <LogoDesignBox>
                <AvatarBox
                  src={preview ? preview.toString() : "/broken-image.jpg"}
                />
                <CompanyNameTextSTyle>
                  {company.companyName}
                </CompanyNameTextSTyle>
              </LogoDesignBox>

              <MainGridContainer>
                <GridItem md={12} width= "100%" paddingTop="130px">
                  <Grid
                    container
                    display={"flex"}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Grid item md={4}>
                      <Grid container direction={"column"}>
                        <Grid item>
                          <CompanyNameText gutterBottom>
                            Company Profile
                          </CompanyNameText>
                        </Grid>
                        <Grid item>
                          <DetailFormText>
                            Update your company photo and details here.
                          </DetailFormText>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={8}>
                      <GridContainer
                        direction="row"
                        alignItems={ "center"}
                        justifyContent="flex-end"
                      >
                        {/* Save Button */}
                        {(editState.logo || editState.country) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              formik.handleSubmit();
                              setIsEdited({
                                ...isEdited,
                                logo: false,
                                country: false,
                              });
                            }}
                            disabled={
                              !(
                                // Check if logo has changed, no errors, and is valid
                                (
                                  (isEdited.logo &&
                                    !formik.errors.companyLogo &&
                                    formik.values.companyLogo &&
                                    formik.values.companyLogo !==
                                      formik.initialValues.companyLogo) ||
                                  // Check if country has changed, no errors, and is valid
                                  (isEdited.country &&
                                    !formik.errors.country &&
                                    formik.values.country &&
                                    formik.values.country !==
                                      formik.initialValues.country &&
                                    formik.values.country !== "")
                                )
                              )
                            }
                            style={{ marginLeft: "10px" }}
                          >
                            Save
                          </Button>
                        )}
                      </GridContainer>
                    </Grid>
                  </Grid>
                  <CustomDivider />
                </GridItem>

                <GridItem md={12} paddingTop= "15px" >
                  <Grid container alignItems={"center"} rowSpacing={"30px"}>
                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Company Logo</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <GridContainer mb={"10px"}>
                        <GridItem xs={12} md={12}>
                          <TextfieldGridContainer>
                            <GridItem xs={4.5} md={4.5}>
                              <AvatarBoxLogo
                                src={
                                  preview
                                    ? preview.toString()
                                    : "/broken-image.jpg"
                                }
                              />
                            </GridItem>

                            {/* Edit Icon */}
                            <GridItem xs={7.5} md={7.5}>
                              {editState.logo ? (
                                <UploadTextField
                                  name="companyLogo"
                                  setPreview={setPreview}
                                />
                              ) : (
                                <IconButtonStyle
                                  onClick={() => {
                                    setEditState({
                                      ...editState,
                                      logo: !editState.logo,
                                    });
                                    setIsEdited({ ...isEdited, logo: true });
                                  }}
                                >
                                  <EditIcon />
                                </IconButtonStyle>
                              )}

                              {/* Display Error Message */}
                              {!!formik.errors.companyLogo && (
                                <AlertStyle
                                  severity="error"
                                >
                                  {formik.errors.companyLogo}
                                </AlertStyle>
                              )}
                            </GridItem>
                          </TextfieldGridContainer>
                        </GridItem>
                      </GridContainer>
                    </Grid>

                    {/* Company Name */}
                    <GridItem md={4} xs={10}></GridItem>
                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Company Name</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <Typography>{company.companyName}</Typography>
                    </Grid>
                    <GridItem md={4} xs={10}></GridItem>

                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Company Reg No.</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <Typography>{company.companyRegNo}</Typography>
                    </Grid>
                    <GridItem md={4} xs={10}></GridItem>

                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Company Code</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <Typography>{company.companyCode}</Typography>
                    </Grid>
                    <GridItem md={4} xs={10}></GridItem>

                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Country</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <TextfieldGridContainer>
                        <Grid item md={12} xs={10}>
                          {editState.country ? (
                            <CountrySelect2
                              name="country"
                              value={formik.values.country}
                            />
                          ) : (
                            <Typography> {formik.values.country}</Typography>
                          )}
                        </Grid>
                        {!editState.country && (
                          <IconButtonStyleEdit
                            onClick={() => {
                              setEditState({ ...editState, country: true });
                              setIsEdited({ ...isEdited, country: true });
                            }}
                          >
                            <EditIcon />
                          </IconButtonStyleEdit>
                        )}
                      </TextfieldGridContainer>
                    </Grid>

                    {/* Profile Info  */}
                    <GridItem md={4} xs={10}></GridItem>

                    <GridItem
                      md={12}
                      paddingTop="42px"
                      width= "100%"
                    >
                      <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        alignItems={"center"}
                      >
                        <Grid item md={4}>
                          <Grid container direction={"column"}>
                            <Grid item>
                              <CompanyNameText gutterBottom>
                                Profile info
                              </CompanyNameText>
                            </Grid>
                            <Grid item>
                              <DetailFormText>
                                Update your personal details here.
                              </DetailFormText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={8}>
                          <Grid
                            container
                            direction="row"
                            justifyContent= "flex-end"
                            alignItems= "center"
                          >
                            
                            {/* Save Button */}
                            {(editState.phoneNumber || editState.name) && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  formik.handleSubmit();
                                  setIsEdited({
                                    ...isEdited,
                                    phoneNumber: false,
                                    name: false,
                                  });
                                }}
                                disabled={
                                  !(
                                    // Check if either 'name' or 'phoneNumber' has valid changes and no errors
                                    (
                                      (isEdited.name &&
                                        !formik.errors.name &&
                                        formik.values.name &&
                                        formik.values.name !==
                                          formik.initialValues.name) ||
                                      (isEdited.phoneNumber &&
                                        !formik.errors.phoneNumber &&
                                        formik.values.phoneNumber &&
                                        formik.values.phoneNumber !==
                                          formik.initialValues.phoneNumber)
                                    )
                                  )
                                }
                                style={{ marginLeft: "10px" }}
                              >
                                Save
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <CustomDivider />
                    </GridItem>

                    {/* Name */}
                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Name</StyledFormLabel>
                    </Grid>

                    <Grid item md={3.5} xs={10}>
                      <TextfieldGridContainer>
                        <GridItem md={12}>
                          {editState.name ? (
                            <InputField2
                              name="name"
                              value={formik.values.name}
                            />
                          ) : (
                            <Typography> {formik.values.name}</Typography>
                          )}
                        </GridItem>
                        {!editState.name && (
                          <IconButtonStyleEdit
                            onClick={() => {
                              setEditState({ ...editState, name: true });
                              setIsEdited({ ...isEdited, name: true });
                            }}
                          >
                            <EditIcon />
                          </IconButtonStyleEdit>
                        )}
                      </TextfieldGridContainer>
                    </Grid>
                    <GridItem md={4} xs={10}></GridItem>

                    {/* Phone Number */}
                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Phone Number</StyledFormLabel>
                    </Grid>

                    <Grid item md={3.5} xs={10}>
                      <TextfieldGridContainer>
                        <GridItem md={12}>
                          {editState.phoneNumber ? (
                            <PhoneField2
                              name="phoneNumber"
                              value={formik.values.phoneNumber}
                            />
                          ) : (
                            <Typography>
                              {" "}
                              {formik.values.phoneNumber}
                            </Typography>
                          )}
                        </GridItem>
                        <GridItem xs={4}>
                          {!editState.phoneNumber && (
                            <IconButtonStyleEdit
                              onClick={() => {
                                setEditState({
                                  ...editState,
                                  phoneNumber: true,
                                });
                                setIsEdited({ ...isEdited, phoneNumber: true });
                              }}
                            >
                              <EditIcon />
                            </IconButtonStyleEdit>
                          )}
                        </GridItem>
                      </TextfieldGridContainer>
                    </Grid>
                    <GridItem md={4} xs={10}></GridItem>

                    {/* Email */}
                    <Grid item md={3.5} xs={10}>
                      <StyledFormLabel>Email</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <Typography>{formik.values.email}</Typography>
                    </Grid>

                    {/* change pass word */}
                    <GridItem
                      md={12}
                      paddingTop= "100px" 
                      width= "100%"
                    >
                      <Grid container direction={"column"}>
                        <GridItem md={12} width= "100%" >
                          <Grid container direction="row" alignItems="center">
                            <Grid item md={2}>
                              <CompanyNameText gutterBottom>
                                Change Password
                              </CompanyNameText>
                            </Grid>
                            <Grid item md={2}>
                              {!editState.Password && (
                                <PasswordIconButtonStyle
                                  onClick={() => {
                                    setEditState({
                                      ...editState,
                                      Password: true,
                                    });
                                    setIsEdited({
                                      ...isEdited,
                                      Password: true,
                                    });
                                  }}
                                >
                                  <EditIcon />
                                </PasswordIconButtonStyle>
                              )}
                            </Grid>
                            <Grid item md={8}>
                              <Grid
                                container
                                direction="row"
                                  justifyContent= "flex-end"
                                  alignItems= "center"
                              >
                                {/* Save Button */}
                                {editState.Password && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      formik.handleSubmit();
                                      setIsEdited({
                                        ...isEdited,
                                        Password: false,
                                      });
                                    }}
                                    disabled={
                                      !isEdited.Password ||
                                      !!formik.errors.password ||
                                      !!formik.errors.newPassword ||
                                      !!formik.errors.confirmPassword ||
                                      !formik.values.password ||
                                      !formik.values.newPassword ||
                                      !formik.values.confirmPassword ||
                                      formik.values.newPassword !==
                                        formik.values.confirmPassword
                                    }
                                    style={{ marginBottom: "10px" }}
                                  >
                                    Save
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <CustomDivider />
                        </GridItem>

                        {editState.Password && (
                          <GridItem md={12} paddingTop= "42px" >
                            <Grid
                              container
                              alignItems={"center"}
                              rowSpacing={"30px"}
                            >
                              {(formik.errors.password ||
                                formik.errors.newPassword) && (
                                <React.Fragment>
                                  <Grid item md={5} xs={10} />
                                  <Grid item md={10} xs={10}>
                                    <Alert severity="error">
                                      {formik.errors.newPassword ||
                                        formik.errors.password}
                                    </Alert>
                                  </Grid>
                                </React.Fragment>
                              )}
                              <Grid item md={3.5} xs={10}>
                                <StyledFormLabel>
                                  Current Password
                                </StyledFormLabel>
                              </Grid>
                              <Grid item md={3.5} xs={10}>
                                <InputField2
                                  name="password"
                                  type="password"
                                  onFocus={() =>
                                    formik.setFieldError("password", "")
                                  }
                                  error={Boolean(formik.errors.password)}
                                />
                              </Grid>
                              <GridItem md={4} xs={10}></GridItem>

                              <Grid item md={3.5} xs={10}>
                                <StyledFormLabel>New Password</StyledFormLabel>
                              </Grid>
                              <Grid item md={3.5} xs={10}>
                                <InputField2
                                  name="newPassword"
                                  type="password"
                                  onFocus={() =>
                                    formik.setFieldError("newPassword", "")
                                  }
                                  error={Boolean(formik.errors.newPassword)}
                                />
                              </Grid>
                              <GridItem md={4} xs={10}></GridItem>

                              <Grid item md={3.5} xs={10}>
                                <StyledFormLabel>
                                  Confirm Password
                                </StyledFormLabel>
                              </Grid>
                              <Grid item md={3.5} xs={10}>
                                <InputField2
                                  name="confirmPassword"
                                  type="password"
                                  error={Boolean(formik.errors.confirmPassword)}
                                />
                              </Grid>
                            </Grid>
                          </GridItem>
                        )}
                        <Grid padding={"25px"}></Grid>
                      </Grid>
                    </GridItem>
                  </Grid>
                </GridItem>
              </MainGridContainer>
            </MarginBox>
          </MainBox>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileSettings;