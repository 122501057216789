import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PublicLayout_01 from './Layout/PublicLayout/index';
import theme from './components/theme';
import { ThemeProvider } from '@mui/material/styles';
import LoginForm from './components/Forms/Login';
import Multi from './components/Forms/RegisterForm/last/index';
import OtpVerificationForm from './components/Forms/OTP';
import ProfileSettings from './Layout/PrivateLayout/Profile';
import PrivateLayout from './Layout/PrivateLayout';
import Home from './pages/Home';
import { apiUrls } from './Utilities/properties';
import { AvatarProvider } from './context/AvatarContext';
import EmailRequest from './components/forgetEmailRequest';
import ResetPasswordForm from './components/forgetPassword';
import CssBaseline from "@mui/material/CssBaseline";



const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <AvatarProvider>
    <Router>
      <Routes>
      <Route
            path=""
            element={<Navigate to={apiUrls.LOG_IN} replace />}
          />
        <Route path={apiUrls.LOG_IN} element={<PublicLayout_01 verifyState={false}><LoginForm/></PublicLayout_01>} />
        <Route path={apiUrls.REGISTER} element={<Multi/>} />
        <Route path={apiUrls.DASHBOARD} element={<Home/>} />
        <Route path={apiUrls.VERIFY_EMAIL} element={<PublicLayout_01 verifyState={true}><OtpVerificationForm /></PublicLayout_01>} />
        <Route path={apiUrls.PROFILE} element={<PrivateLayout><ProfileSettings/></PrivateLayout>} />
        <Route path={apiUrls.FORGOT_PASSWORD} element={<EmailRequest/>} />
        <Route path={apiUrls.RESET_PASSWORD} element={<ResetPasswordForm/>} />
      </Routes>
    </Router>
    </AvatarProvider>
    </ThemeProvider>

  );
};

export default App;
