import React from "react";
import { GridItem } from "../../components/StyledComponents/Index";
import {
  LayoutImageContainer,
  PublicLayoutContainer,
  LayoutImageItem,
  LayoutFormContainer,
  LayoutFormItem,
  FormContainer,
} from "./style";
import { Box } from "@mui/material";
import { LogoImage, RobotImage } from "../../assets/svg";

interface PublicLayoutProps {
  children: React.ReactNode;
  verifyState: boolean;
}
const PublicLayout_01: React.FC<PublicLayoutProps> = ({
  children,
  verifyState,
  ...props
}) => {
  return (
    <PublicLayoutContainer>
      <GridItem xs={12} md={4.5}>
        <LayoutImageContainer>
          <LayoutImageItem md={12}>
            <LogoImage />
            <RobotImage verifiedState={verifyState}  />
          </LayoutImageItem>
        </LayoutImageContainer>
      </GridItem>
      <GridItem xs={12} md={7.5}>
        <LayoutFormContainer>
          <LayoutFormItem md={8}>
            <FormContainer>
              {/* <Box sx={{paddingTop:'30%'}}>{children}</Box> */}
              <Box
                sx={{
                  paddingTop: "25%",
                  // maxHeight: "500px",
                  overflow: {md: 'hidden', lg:'hidden'},
                }}
              >
                <Box
                  sx={{
                    transform: {
                      md: "scale(0.65)", // 70% on medium devices
                      lg: "scale(0.75)", // 60% on large devices
                    },
                    transformOrigin: "top center",
                  }}
                >
                  {children}
                </Box>
              </Box>

              {/* <Box sx={{ flexGrow: 3,}} {...props}>
                {children}
              </Box>
              <Box sx={{ flexGrow: 1, backgroundColor:'red'}} /> */}
            </FormContainer>
          </LayoutFormItem>
        </LayoutFormContainer>
      </GridItem>
    </PublicLayoutContainer>
  );
};

export default PublicLayout_01;
